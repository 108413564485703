<template>
	<div>
		<user-list-add-new
			:is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
			:role-options="roleOptions"
			:plan-options="planOptions"
			@refetch-data="refetchData"
		/>

		<!-- Table Container Card -->
		<b-card no-body class="mb-0">
			<div class="m-2">
				<!-- Table Top -->
				<b-row>
					<!-- Per Page -->
					<b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
						<label>Show</label>
						<v-select
							v-model="perPage"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							:options="perPageOptions"
							:clearable="false"
							class="per-page-selector d-inline-block mx-50"
						/>
						<label>entries</label>
					</b-col>

					<!-- Search -->
					<b-col cols="12" md="6">
						<div class="d-flex align-items-center justify-content-end">
							<!-- <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..." /> -->
							<b-button variant="primary" v-b-modal.modal-customer>
								<span class="text-nowrap">Add User</span>
							</b-button>
						</div>
						<b-modal
							id="modal-customer"
							cancel-variant="outline-secondary"
							ok-title="Save"
							cancel-title="Cancel"
							centered
							title="Kundendaten bearbeiten"
							@ok="newCustomer()"
						>
							<b-form>
								<b-row>
									<b-col cols="6">
										<b-form-group label="Firmenname" label-for="name">
											<b-form-input id="name" v-model="newCustomerData.name" />
										</b-form-group>
									</b-col>
									<b-col cols="4">
										<b-form-group v-if="newCustomerData.exemptVat" label="Vat Number" label-for="vatNumber">
											<b-form-input id="vatNumber" v-model="newCustomerData.vatNumber" />
										</b-form-group>
									</b-col>
									<b-col cols="2">
										<b-form-group label="USt." label-for="exemptVat">
											<b-form-checkbox v-model="newCustomerData.exemptVat" name="exemptVat" switch inline>
											</b-form-checkbox>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col cols="6">
										<b-form-group label="First Name" label-for="surename">
											<b-form-input id="surename" v-model="newCustomerData.surename" />
										</b-form-group>
									</b-col>
									<b-col cols="6">
										<b-form-group label="Last Name" label-for="familyname">
											<b-form-input id="familyname" v-model="newCustomerData.familyname" />
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-form-group label="Telefon" label-for="phone">
											<b-form-input id="phone" v-model="newCustomerData.phone" type="text" />
										</b-form-group>
									</b-col>
								</b-row>
								<b-row>
									<b-col>
										<b-form-group label="Main Email" label-for="email">
											<b-form-input id="email" v-model="newCustomerData.email" type="email" />
										</b-form-group>
									</b-col>
								</b-row>
							</b-form>
						</b-modal>
					</b-col>
				</b-row>
			</div>

			<b-table
				ref="refUserListTable"
				class="position-relative"
				:items="fetchUsers"
				responsive
				:fields="tableColumns"
				primary-key="id"
				:sort-by.sync="sortBy"
				show-empty
				empty-text="No matching records found"
				:sort-desc.sync="isSortDirDesc"
			>
				<!-- Column: User -->
				<template #cell(user)="data">
					<b-media vertical-align="center">
						<template #aside>
							<b-avatar
								size="32"
								:src="data.item.avatar"
								:text="avatarText(data.item.surename + ' ' + data.item.familyname)"
								:variant="`light-${resolveUserRoleVariant(data.item.role)}`"
								:to="{ name: 'wercbrain-customers-edit', params: { id: data.item._id } }"
							/>
						</template>
						<b-link
							:to="{ name: 'wercbrain-customers-edit', params: { id: data.item._id } }"
							class="font-weight-bold d-block text-nowrap"
						>
							{{ data.item.surename }} {{ data.item.familyname }}
						</b-link>
						<small class="text-muted">{{ data.item.email }}</small>
					</b-media>
				</template>

				<!-- Column: Address -->
				<template #cell(address)="data">
					<div class="text-nowrap" vertical-align="center">
						<div class="align-text-top text-capitalize">
							{{ data.item.name }}
						</div>
					</div>
				</template>

				<!-- Column: Last order -->
				<template #cell(lastOrder)="data">
					<div class="text-nowrap" vertical-align="center">
						<div class="align-text-top text-capitalize">{{ formatDate(data.item.createdAt) }}</div>
					</div>
				</template>

				<!-- Column: Actions -->
				<template #cell(actions)="data">
					<div class="text-nowrap" vertical-align="center">
						<b-button @click="() => startWercsterAsCustomer(data.item)"> wercster als Kunde starten </b-button>
					</div>
				</template>
			</b-table>
			<div class="mx-2 mb-2">
				<b-row>
					<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
						<span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
					</b-col>
					<!-- Pagination -->
					<b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
						<b-pagination
							v-model="currentPage"
							:total-rows="totalUsers"
							:per-page="perPage"
							first-number
							last-number
							class="mb-0 mt-1 mt-sm-0"
							prev-class="prev-item"
							next-class="next-item"
						>
							<template #prev-text>
								<feather-icon icon="ChevronLeftIcon" size="18" />
							</template>
							<template #next-text>
								<feather-icon icon="ChevronRightIcon" size="18" />
							</template>
						</b-pagination>
					</b-col>
				</b-row>
			</div>
		</b-card>
		<b-card class="mt-3 text-right">
			<b-button variant="link" :disabled="syncing" @click="syncSevdeskUsers" class="ml-1">
				<span class="text-nowrap">Neue Kunden aus Sevdesk importieren</span>
			</b-button>
		</b-card>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BForm,
	BFormGroup,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import userStoreModule from '../userStoreModule'
import UserListAddNew from './UserListAddNew.vue'
import dayjs from 'dayjs'

export default {
	components: {
		UsersListFilters,
		UserListAddNew,
		BCard,
		BFormCheckbox,
		BRow,
		BForm,
		BFormGroup,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,

		vSelect,
	},
	setup() {
		const USER_APP_STORE_MODULE_NAME = 'app-user'

		// Register module
		if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

		// UnRegister on leave
		onUnmounted(() => {
			if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
		})

		const isAddNewUserSidebarActive = ref(false)

		const roleOptions = [
			{ label: 'Admin', value: 'admin' },
			{ label: 'Author', value: 'author' },
			{ label: 'Editor', value: 'editor' },
			{ label: 'Maintainer', value: 'maintainer' },
			{ label: 'Subscriber', value: 'subscriber' },
		]

		const planOptions = [
			{ label: 'Basic', value: 'basic' },
			{ label: 'Company', value: 'company' },
			{ label: 'Enterprise', value: 'enterprise' },
			{ label: 'Team', value: 'team' },
		]

		const statusOptions = [
			{ label: 'Pending', value: 'pending' },
			{ label: 'Active', value: 'active' },
			{ label: 'Inactive', value: 'inactive' },
		]

		const {
			fetchUsers,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserListTable,
			refetchData,
			syncSevdeskUsers,
			syncing,
			// UI
			resolveUserRoleVariant,
			resolveUserRoleIcon,
			resolveUserStatusVariant,
			// Extra Filters
			roleFilter,
			planFilter,
			statusFilter,
		} = useUsersList()

		return {
			// Sidebar
			isAddNewUserSidebarActive,
			fetchUsers,
			tableColumns,
			perPage,
			currentPage,
			totalUsers,
			dataMeta,
			perPageOptions,
			searchQuery,
			sortBy,
			isSortDirDesc,
			refUserListTable,
			refetchData,
			syncSevdeskUsers,
			syncing,

			// Filter
			avatarText,

			// UI
			resolveUserRoleVariant,
			resolveUserRoleIcon,
			resolveUserStatusVariant,

			roleOptions,
			planOptions,
			statusOptions,

			// Extra Filters
			roleFilter,
			planFilter,
			statusFilter,
		}
	},
	data() {
		let selected
		const newCustomerData = {
			name: '',
			email: '',
			surename: '',
			familyname: '',
			phone: '',
			vatNumber: '',
			exemptVat: false,
		}
		return {
			selected,
			newCustomerData,
			wercsterBaseUrl: process.env.VUE_APP_WERCSTER_BASE_URL,
		}
	},
	methods: {
		newCustomer() {
			let data = this.newCustomerData
			console.log(this.perPage)
			this.$store
				.dispatch('Customers/newCustomer', data)
				.then((res) => {
					this.refetchData()
				})
				.finally(() => {
					setTimeout(() => (this.saving = false), 500)
				})
		},
		formatDate(date) {
			return dayjs(date).format('DD.MM.YYYY')
		},
		startWercsterAsCustomer(customer) {
			this.$store.dispatch('Auth/getOneTimeToken').then((res) => {
				console.log(res)
				if (res && res.token)
					window.open(`${this.wercsterBaseUrl}/request-as-customer?customer=${customer._id}&token=${res.token}`, '_blank')
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.per-page-selector {
	width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
